<template>
  <div class="content">
      <div class="title">{{item.name}}</div>
      <div class="xz" v-if="item.type=='文件'" @click="downLoad(item.fileUrl,item.id)">
        <img src="../../../../assets/books/xz-img.png" alt="">
        <div class="xz-title">下载</div>
      </div>
      <div class="bf" v-if="item.type=='视频'||item.type=='音频'" @click.stop="playMethod()">
        <img src="../../../../assets/books/bf-img.png" alt="">
        <div class="bf-title">播放</div>
      </div>
      <el-dialog title="视频" class="dialog" :width="'1000px'" :visible.sync="dialogFormVisible" :close-on-click-modal=false>
        <videos :width="960" :srcUrl="item.fileUrl" :height="452" v-if="dialogFormVisible"></videos>
      </el-dialog>
	  <el-dialog title="音频" class="dialog" :width="'500px'" :visible.sync="dialogFormVisibles" :close-on-click-modal=false>
		<div class="yin">
			<audio :src="item.fileUrl" autoplay="autoplay" controls="controls" ref="audio" v-if="dialogFormVisibles">Your browser does not support the audio element.</audio>
		</div>
	  </el-dialog>
  </div>
</template>

<script>
import videos from '../../../../components/tools/video-box'
export default {
    components:{videos},
    data() {
        return {
          dialogFormVisible:false,
			    dialogFormVisibles:false,
			    txt:false
        }
    },
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        },
    },
    methods:{
		getDownloads(index){
			var that = this
			that.$api.getDownloads({
				id:index
			}).then(res=>{
				if (res.data.code == 0) {
					
				}else{
					
				}
			})
		},
		/**下载**/ 
		downLoad(data,index){
			this.getDownloads(index)
			var arr = data.split('.');
			if (arr[arr.length-1] == 'txt' || arr[arr.length-1] == 'jpg' || arr[arr.length-1] == 'png') {
				this.downloadUrlFile(data);
			}else{
				var aLink = document.createElement("a");
				aLink.style.display = "none";
				aLink.href = data;
				aLink.setAttribute("download", name);
				document.body.appendChild(aLink);
				aLink.click();
				document.body.removeChild(aLink); //下载完成移除元素
				window.URL.revokeObjectURL(data);
			}
		},
		/**
		* 获取页面文件名
		* @param url 文件url
		*/
		downloadUrlFile(url) {
		    url = url.replace(/\\/g, '/');
		    const xhr = new XMLHttpRequest();
		    xhr.open('GET', url, true);
		    xhr.responseType = 'blob';
		    xhr.onload = () => {
		        if (xhr.status === 200) {
		            // 获取文件blob数据并保存
		            var fileName = this.getFileName(url);
		            this.saveAs(xhr.response, fileName);
		        }
		    };
		    xhr.send();
		},
		
		/**
		* URL方式保存文件到本地
		* @param data 文件的blob数据
		* @param name 文件名
		*/
		saveAs(data, name) {
		    var urlObject = window.URL || window.webkitURL
		    var export_blob = new Blob([data])
		    var save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
		    save_link.href = urlObject.createObjectURL(export_blob);
		    save_link.download = name;
		    save_link.click();
		},
		
		/**
		 * 根据文件url获取文件名
		 * @param url 文件url*/
		getFileName(url) {
		    var num = url.lastIndexOf('/') + 1
		    var fileName = url.substring(num)
		    //把参数和文件名分割开
		    fileName = decodeURI(fileName.split("?")[0]);
		    return fileName;
		},
		/**播放音视频**/ 
        playMethod(){
			if (this.item.type=='视频') {
				this.dialogFormVisible = true
			}else{
				this.dialogFormVisibles = true
				this.play()
			}
        },
		/**播放**/
		play() {
		  this.dialogFormVisibles = true;
		  this.$refs.audio.play();
		},
		/**音频暂停**/
		stop() {
		  this.dialogFormVisibles = false;
		  this.$refs.audio.pause();
		  this.$refs.audio.currentTime = 0;
		}
    }
}
</script>

<style scoped lang="scss">
.content{
    width: 100%;
    height: 30px;
    display: flex;
    justify-items: center;
    align-items: center;
    position: relative;
    margin-top: 20px;
    .title{
        font-size: 14px;
        line-height: 30px;
        color: #333333;
    }
    .xz{
        position: absolute;
        right: 0px;
        display: flex;
        justify-items: center;
        align-items: center;
        cursor: pointer;
        > img{
            display: flex;
            justify-items: center;
            align-items: center;
            padding-right: 5px;
        }
        .xz-title{
            font-size: 14px;
            line-height: 30px;
            color: #333333;
        }
    }
    .bf{
        position: absolute;
        right: 0px;
        display: flex;
        justify-items: center;
        align-items: center;
        cursor: pointer;
        > img{
            display: flex;
            justify-items: center;
            align-items: center;
            padding-right: 5px;
        }
        .bf-title{
            font-size: 14px;
            line-height: 30px;
            color: #333333;
        }
    }
}
.yin{
	margin-left: 80px;
}
</style>
<template>
  <div class="main-view">
    <div class="contents-view">
      <!-- 面包屑 -->
      <div class="bread">
        <bread :fromFather="dataPath"></bread>
      </div>
      <!-- 图书信息 -->
      <div class="book-xx">
        <div class="bookLogo">
          <el-image
            :src="booksItem.img"
            :preview-src-list="srcList">
          </el-image>
        </div>
        <div class="xx-right">
          <div class="book-title">{{'《'+booksItem.name+'》'}}</div>
          <div class="book-author" v-if="booksItem.opus!=null">{{booksItem.opus+'  '+'著'}}</div>
<!--          <p>编辑推荐语</p>-->
          <div class="book-content"  v-html="booksItem.bookInfo"></div>
          <div class="btn-view">
            <div class="price">{{'¥'+booksItem.price}}</div>
            <div class="buy" :style="{backgroundColor:isSkin}" @click.stop="buyMethod()">前往购买</div>
            <div class="yangb" @click.stop="sampleMethod()">申请样书</div>
            <div class="shidu" @click.stop="readingMethod()">在线试读</div>
          </div>
        </div>
      </div>
      <!-- 分享/收藏 -->
      <div class="interactions">
        <div class="fx-view" @click.stop="fxMethod()">
          <div>
            <img src="../../../assets/books/fx-img.png" alt="">
          </div>
          <div>分享</div>
        </div>
        <div class="sc-view" @click.stop="scMethod()">
          <div>
            <img src="../../../assets/books/sc-img.png" alt="">
          </div>
		  <div v-show="booksItem.isCollection == 1">{{'已收藏'+booksItem.collectNumber+'次'}}</div>
          <div v-show="booksItem.isCollection == 0">{{'收藏'+booksItem.collectNumber+'次'}}</div>
        </div>
        <div class="gk-view">
          <div>
            <img src="../../../assets/books/gk-img.png" alt="">
          </div>
          <div>{{'阅读'+booksItem.readNumber+'次'}}</div>
        </div>
      </div>
      <!-- 介绍 -->
      <div class="content-list">
        <!-- 左边内容 -->
        <div class="left-view">
          <div class="cilck-view">
            <div class="cilck-one" :style="isCilck==1?{backgroundColor:isSkin,color:'#FFF'}:''" @click.stop="introduceMethod()">商品介绍</div>
            <div class="cilck-two" :style="isCilck==2?{backgroundColor:isSkin,color:'#FFF'}:''" @click.stop="resourcesMethod()">配套资源</div>
          </div>
          <div class="line"></div>
          <div class="nr-view">
            <div class="js-view" v-if="isCilck==1">
              <introduceList v-show="booksItem.introduce!=''" :title="'简介'" :content="booksItem.introduce"></introduceList>
			  <introduceList v-show="booksItem.preface!=''" :title="'前言'" :content="booksItem.preface"></introduceList>
			  <introduceList v-show="booksItem.awardWinning!=''" :title="'获奖'" :content="booksItem.awardWinning"></introduceList>
			  <introduceList v-show="booksItem.bookInfo!=''" :title="'推荐语'" :content="booksItem.recommendation"></introduceList>
			  <introduceList v-show="booksItem.catalogue!=''" :title="'目录'" :content="booksItem.catalogue"></introduceList>
            </div>
            <div class="zy-view" v-if="isCilck==2">
              <div class="itemlist">
                <itemTitle v-for="(item,index) in typeList" :key="index" :cur="index" :index="cur" :item="item" @cilckMethod="cilckMethod"></itemTitle>
              </div>
              <div class="filelist">
                <fileList v-for="(item,index) in fileList" :key="index" :item="item"></fileList>
              </div>
            </div>
          </div>
        </div>
        <!-- 右边内容 -->
        <div class="right-view">
          <!-- 出版信息 -->
          <bookInfo :item="booksItem"></bookInfo>
          <!-- 作者简介 -->
          <brief class="brief-view" :item="booksItem"></brief>
          <!-- 同系列图书 -->
		  <bookSeries class="bookSeries-view" :title="'系列图书'" :list="seriesList" @detailsMethod="detailsMethod"></bookSeries>
          <bookSeries class="bookSeries-view" :title="'相关图书'" :list="relevantList" @detailsMethod="detailsMethod"></bookSeries>
        </div>
      </div>
    </div>
    <!-- 分享 -->
    <el-dialog title="分享" class="dialog" :width="'488px'" :visible.sync="isShare" :close-on-click-modal=false>
      <div class="share-view">
        <div class="wx" @click.stop="wxMethod()">
          <img src="../../../assets/wx-icon.png" alt="">
        </div>
        <div class="wb"  @click.stop="wbMethod()">
          <img src="../../../assets/wb-icon.png" alt="">
        </div>
        <div class="qq"  @click.stop="qqMethod()">
          <img src="../../../assets/qq-icon.png" alt="">
        </div>
      </div>
    </el-dialog>
    <!-- 微信二维码 -->
    <el-dialog title="微信分享二维码" class="dialog" :width="'488px'" :visible.sync="isWXCode" :close-on-click-modal=false @close="WXcloseMethod()">
      <div class="share-wx" v-show="isWXCode">
        <div id="qrcode" ref="qrcode"></div>
        <div class="title" v-html="'手机微信扫描二维码,点击右上角···按钮<br>分享给微信好友或微信朋友圈'"></div>
      </div>
    </el-dialog>
    <!-- QQ二维码 -->
    <el-dialog title="QQ分享二维码" class="dialog" :width="'488px'" :visible.sync="isQQCode" :close-on-click-modal=false @close="QQcloseMethod()">
      <div class="share-qq">
        <div id="qqcode" ref="qqcode"></div>
        <div class="title" v-html="'手机QQ扫描二维码,点击右上角···按钮<br>分享给QQ好友或QQ空间'"></div>
      </div>
    </el-dialog>
    <!-- 购买链接 -->
    <el-dialog title="图书购买链接" class="dialog" :width="'488px'" :visible.sync="isLink" :close-on-click-modal=false>
      <div class="link">
        <div class="linkItem" v-for="(item,index) in linkList" :key="index" @click.stop="linkMethod(item.url)">{{item.name}}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Message} from 'element-ui'
import bread from '../../../components/tools/bread-box'
import introduceList from './components/introduceList-box'
import itemTitle from './components/item-box'
import fileList from './components/fileList-box'
import bookInfo from './components/bookInfo-box'
import brief from './components/brief-box'
import bookSeries from './components/bookSeries-box'
import QRCode from 'qrcodejs2'
export default {
	components:{bread,introduceList,itemTitle,fileList,bookInfo,brief,bookSeries},
	data() {
		return {
      isLink:false,
      isWXCode:false,
      isQQCode:false,
      isShare:false,
			booksItem:{},
			bookID:'',
			cur:0,
			isCilck:1,
      linkList:[],
			dataPath:[{
				path:'books',
				name:'图书',
			},{
				path:'bookRecordSearch',
				name:'图书详情',
			}],
			relevantList:[],
			seriesList:[],
			typeList:[],
			fileList:[],
			buyUrl:'',
      srcList:[]
		}
	},
	created(){
		this.bookID = this.$route.query.bookID
		this.$store.commit('changClick',1)
		var skin = sessionStorage.getItem('Skin')
		var zskin = sessionStorage.getItem('ZSkin')
		if (skin) {
			this.$store.commit('changSkin',skin)
		}
		if (zskin) {
			this.$store.commit('changZSkin',zskin)
		}
    this.getUserInfo()
    this.getSystemColor()
		this.getBookSDetails()
		this.getRelevantBooks()
		this.getSeriesBooks()
		this.getBooksResourceType()
		this.getBooksBrowse(1)
	},
	computed:{
		isSkin(){
			return this.$store.state.isSkin
		}
	},
	methods:{
    /**获取用户信息**/
		getUserInfo(){
			let that = this
			that.$api.getUserInfo({
				file:'',
			}).then(res=>{
				if (res.data.code == 0){
					sessionStorage.setItem('userNickName',res.data.data.nickname)
					sessionStorage.setItem('userHeadImg',res.data.data.headImgUrl)
					sessionStorage.setItem('bindWechat',res.data.data.bindWechat)
					this.$store.commit('changNickname',res.data.data.nickname)
					this.$store.commit('changHeadImgUrl',res.data.data.headImgUrl)
					this.$store.commit('changTeachers',res.data.data.identity)
          sessionStorage.setItem('Teachers',res.data.data.identity)
				}
			})
		},
    /**获取背景颜色**/
		getSystemColor(){
			var that = this
			that.$api.getSystemColor({}).then(res=>{
				if (res.data.code == 0) {
					sessionStorage.setItem('Skin',res.data.data.colorValue)
					sessionStorage.setItem('ZSkin',res.data.data.buttonColor)
					this.$store.commit('changSkin',res.data.data.colorValue)
					this.$store.commit('changZSkin',res.data.data.buttonColor)
				}
			})
		},
		/**获取图书详情**/
		getBookSDetails(){
			var that = this
			that.$api.getBooksDetails({
				params:{
					id:that.bookID,
				}
			}).then(res=>{
				if (res.data.code == 0) {
          that.srcList = []
					that.booksItem = res.data.data
          that.booksItem.bookInfo = '<span style="font-size: 17px;font-weight: 900">编 辑 推 荐 语:</span>'+(that.booksItem.bookInfo == null?'': that.booksItem.bookInfo)
          that.srcList.push(res.data.data.img)
				}else{
					that.booksItem = {}
				}
			})
		},
		/**相关图书**/
		getRelevantBooks(){
			var that = this
			that.$api.getRelevantBooks({
				params:{
					id:that.bookID
				}
			}).then(res=>{
				if (res.data.code == 0) {
					that.relevantList = res.data.data
				}else{
					that.relevantList = []
				}
			})
		},
		/**系列图书**/
		getSeriesBooks(){
			var that = this
			that.$api.getSeriesBooks({
				params:{
					id:that.bookID
				}
			}).then(res=>{
				if (res.data.code == 0) {
					that.seriesList = res.data.data
				}else{
					that.seriesList = []
				}
			})
		},
		/**资源分类**/
		getBooksResourceType(){
			var that = this
			that.$api.getBooksResourceType({}).then(res=>{
				if (res.data.code == 0) {
					that.typeList = res.data.data
				}else{
					that.typeList = []
				}
			})
		},
		/**分类资源配套**/
		getBooksResource(dictCode){
			var that = this
			that.$api.getBooksResource({
				params:{
					booksId:that.bookID,
					category:dictCode
				}
			}).then(res=>{
				if (res.data.code == 0) {
					that.fileList = res.data.data
				}else{
					that.fileList = []
				}
			})
		},
		/**浏览**/
		getBooksBrowse(category){
			var that = this
			that.$api.getBooksBrowse({
				booksId:that.bookID,
				category:category
			}).then(res=>{
				if (res.data.code == 0) {
					if (category == 2) {
						if (that.booksItem.isCollection == 0) {
							that.booksItem.isCollection = 1
							that.booksItem.collectNumber+=1
							Message({
								showClose: true,
								message: '收藏成功！',
								type: 'success'
							})
						}else{
							that.booksItem.isCollection = 0
							that.booksItem.collectNumber-=1
							Message({
								showClose: true,
								message: '取消收藏成功！',
								type: 'success'
							})
						}
					}else if (category == 3) {

					}
				}else{
					if (category == 2) {
						if (that.booksItem.isCollection == 0) {
							that.booksItem.isCollection = 0
							Message({
								showClose: true,
								message: '收藏失败！',
								type: 'error'
							})
						}else{
							that.booksItem.isCollection = 1
							Message({
								showClose: true,
								message: '取消收藏失败！',
								type: 'error'
							})
						}
					}else if (category == 3) {

					}
				}
			})
		},
		/**获取图书购买链接**/
		getBooksBuy(){
			var that = this
			that.$api.getBooksBuy({
				params:{
					id:that.bookID,
				}
			}).then(res=>{
				if (res.data.code == 0) {
					that.linkList = res.data.data
          if (that.linkList.length == 0) {
            Message({
              showClose: true,
              message: '暂无购买链接',
              type: 'error'
            })
          }else{
            this.isLink = true
          }
				}else{
					that.linkList = []
				}
			})
		},
		/**购买**/
		buyMethod(){
      this.getBooksBuy()
		},
    linkMethod(url){
      window.open(url)
      this.isLink = false
    },
		/**申请样本**/
		sampleMethod(){
      var token = sessionStorage.getItem('token')
      var teacher = sessionStorage.getItem('Teachers')
			if(token){
        if (teacher == 2) {
          this.$router.push({
            path:'/sampleApplication',
            query:{
              bookID:this.bookID
            }
          });
          this.$store.commit('changClick',1)
        }else{
          Message({
            showClose: true,
            message: '请先申请教师认证后，再申请样书！',
            type: 'error'
          })
        }
			}else{
				Message({
					showClose: true,
					message: '请登陆后再申请样书！',
					type: 'error'
				})
			}
		},
		/**在线试读**/
		readingMethod(){
		var reg = RegExp(/pdf/);
      if (this.booksItem.fileUrl == null || this.booksItem.fileUrl == '' || this.booksItem.fileUrl.indexOf("pdf") == -1) {
        Message({
					showClose: true,
					message: '暂无试读文件或文件格式不正确',
					type: 'error'
				})
        return
      }
			this.$router.push({
				path:'/showPdf',
				query:{
				  fileUrl:this.booksItem.fileUrl,
				}
			});
			this.$store.commit('changClick',1)
		},
		/**分享**/
		fxMethod(){
      this.isShare = true
		},
    /**微信分享**/
    wxMethod(){
      var that = this
      that.isShare = false
      that.isWXCode = true
      that.getBooksBrowse(3)
      let url = 'http://www.abook.cn/#/bookDetails?bookID='+this.booksItem.id
      that.$nextTick(() => {
        if (that.$refs.qrcode != undefined) {
          that.$refs.qrcode.innerHTML = "";
        }
        let qrcode = new QRCode('qrcode',{
          width: 200,
          height: 200,
          text: url
        })
      })
    },
    /**关闭微信二维码弹框**/
    WXcloseMethod(){
      this.isWXCode = false
    },
    /**微博分享**/
    wbMethod(){
      this.isShare = false
      this.getBooksBrowse(3)
      let url = 'http://www.abook.cn/#/bookDetails?bookID='+this.booksItem.id
      var param = {
        url: url,
        type: '3',
        count: '1', /** 是否显示分享数，1显示(可选)*/
        appkey: '', /** 您申请的应用appkey,显示分享来源(可选)*/
        title: this.booksItem.name, /** 分享的文字内容(可选，默认为所在页面的title)*/
        pic: this.booksItem.img, /**分享图片的路径(可选)*/
        ralateUid:'', /**关联用户的UID，分享微博会@该用户(可选)*/
        rnd: new Date().valueOf()
      }
      var temp = [];
      for( var p in param ) {
        temp.push(p + '=' +encodeURIComponent( param[p ] || '' ) )
      }
      var targetUrl = 'http://service.weibo.com/share/share.php?' + temp.join('&');
      window.open(targetUrl)
    },
    /**QQ分享**/
    qqMethod(){
      var that = this
      that.isShare = false
      that.isQQCode = true
      that.getBooksBrowse(3)
      let url = 'http://www.abook.cn/#/bookDetails?bookID='+this.booksItem.id
      that.$nextTick(() => {
        if (that.$refs.qqcode != undefined) {
          that.$refs.qqcode.innerHTML = "";
        }
        let qqcode = new QRCode('qqcode',{
          width: 200,
          height: 200,
          text: url
        })
      })
    },
    /**关闭QQ二维码弹框**/
    QQcloseMethod(){
      this.isQQCode = false
    },
		/**收藏**/
		scMethod(){
			var token = sessionStorage.getItem('token')
			if(token){
				this.getBooksBrowse(2)
			}else{
				Message({
					showClose: true,
					message: '请登陆后再收藏！',
					type: 'error'
				})
			}
		},
		/**商品介绍**/
		introduceMethod(){
		  this.isCilck = 1
		},
		/**配套资源**/
		resourcesMethod(){
		  this.isCilck = 2
		},
		/**配套资源分类切换按钮**/
		cilckMethod(index,code){
		  this.cur = index
		  this.getBooksResource(code)
		},
		/**系列图书详情按钮**/
		detailsMethod(index){
		  this.bookID = index
		  this.getBookSDetails()
		  this.getRelevantBooks()
		  this.getSeriesBooks()
		  this.getBooksResourceType()
		}
	}
}
</script>

<style scoped lang="scss">
.main-view{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .contents-view{
    width: 1200px;
    padding-bottom: 100px;
    /**面包屑**/
    .bread{
      width: 100%;
      margin-top: 20px;
    }
    /**图书信息**/
    .book-xx{
      width: 100%;
      height: 390px;
      display: flex;
      justify-items: center;
      align-items: center;
      margin-top: 10px;
      .bookLogo{
        width: 340px;
        height: 425px;
        display: flex;
        justify-items: center;
        align-items: center;
        overflow: hidden;
        background-color:#FCFCFC;
        > img{
          width: 100%;
          height: 100%;
        }
      }
      .xx-right{
        width: 800px;
        height: 390px;
        margin-left: 20px;
        .book-title{
          font-size: 18px;
          line-height: 30px;
          height: 30px;
          color: #333333;
          margin-top: 10px;
        }
        .book-author{
          font-size: 14px;
          line-height: 20px;
          color: #999999;
          height: 20px;
          margin-top: 10px;
        }
        .book-content{
          width: 760px;
          height: 180px;
          background: #FCFCFC;
          padding: 20px;
          overflow: hidden;
          margin-top: 10px;
          font-size: 14px;
          line-height: 20px;
          color: #333333;
        }
        .btn-view{
          display: flex;
          justify-items: center;
          align-items: center;
          width: 100%;
          height: 52px;
          margin-top: 20px;
          .price{
            font-size: 24px;
            line-height: 52px;
            color: #FE2929;
          }
          .buy{
            width: 164px;
            height: 42px;
            border-radius: 4px;
            font-size: 16px;
            line-height: 42px;
            color: #FFFFFF;
            text-align: center;
            margin-left: 80px;
            cursor: pointer;
          }
          .yangb{
            width: 164px;
            height: 42px;
            background: #96B2D2;
            border-radius: 4px;
            font-size: 16px;
            line-height: 42px;
            text-align: center;
            color: #FFFFFF;
            margin-left: 50px;
            cursor: pointer;
          }
          .shidu{
            width: 164px;
            height: 42px;
            border: 1px solid #DDDDDD;
            box-sizing: border-box;
            border-radius: 4px;
            font-size: 16px;
            line-height: 42px;
            text-align: center;
            color: #333333;
            cursor: pointer;
            margin-left: 50px;
          }
        }
      }
    }
    /**交互**/
    .interactions{
      width: 390px;
      height: 30px;
      display: flex;
      justify-items: center;
      align-items: center;
      margin-top: 30px;
      .fx-view{
        display: flex;
        width: 33%;
        height: 30px;
        justify-items: center;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        line-height: 30px;
        color: #333333;
        > div img{
          display: flex;
          justify-items: center;
          align-items: center;
          padding-right: 10px;
        }
      }
      .sc-view{
        width: 33%;
        height: 30px;
        display: flex;
        justify-items: center;
        align-items: center;
        font-size: 14px;
        line-height: 30px;
        color: #333333;
        cursor: pointer;
        > div img{
          display: flex;
          justify-items: center;
          align-items: center;
          padding-right: 10px;
        }
      }
      .gk-view{
        display: flex;
        justify-items: center;
        align-items: center;
        width: 33%;
        height: 30px;
        font-size: 14px;
        line-height: 30px;
        color: #333333;
        cursor: pointer;
        > div img{
          display: flex;
          justify-items: center;
          align-items: center;
          padding-right: 10px;
        }
      }
    }
    /** 内容**/
    .content-list{
      width: 100%;
      display: flex;
      justify-items: center;
      align-items: flex-start;
      margin-top: 50px;
      .left-view{
        width: 895px;
        background: #FCFCFC;
        border-radius: 4px;
        .cilck-view{
          height: 60px;
          width: 100%;
          display: flex;
          justify-items: left;
          align-items: center;
          .cilck-one{
            width: 124px;
            height: 60;
            text-align: center;
            line-height: 60px;
            font-size: 16px;
            color: #333333;
            cursor: pointer;
            border-radius: 4px 0px 0px 0px;
          }
          .cilck-two{
            width: 124px;
            height: 60;
            text-align: center;
            line-height: 60px;
            font-size: 16px;
            color: #333333;
            cursor: pointer;
            border-radius: 4px 0px 0px 0px;
          }
        }
        .line{
          width: 100%;
          height: 0px;
          border: 1px solid #DDDDDD;
        }
        .nr-view{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .js-view{
            width: 813px;
          }
          .zy-view{
            width: 100%;
            .itemlist{
              display: flex;
              justify-items: center;
              align-items: center;
              margin-top: 30px;
            }
            .filelist{
              width: 813px;
              margin-left: 20px;
              margin-top: 10px;
              padding: 10px 0px 10px 0px;
            }
          }
        }
      }
      .right-view{
        width: 285px;
        margin-left: 20px;
        .brief-view{
          margin-top: 30px;
        }
        .bookSeries-view{
          margin-top: 30px;
        }
      }
    }
  }
}
.share-view{
  display: flex;
  justify-content: center;
  align-items: center;
  .wx{
    margin-right: 60px;
    cursor: pointer;
  }
  .wb{
    margin-right: 60px;
    cursor: pointer;
  }
  .qq{
    cursor: pointer;
  }
}
.share-wx{
  display: flex;
  flex-direction: column;
  align-items: center;
  .title{
    width: 80%;
    font-size: 20px;
    color: #333333;
    margin-top: 20px;
    text-align: center;
    line-height: 30px;
  }
}
.share-qq{
  display: flex;
  flex-direction: column;
  align-items: center;
  .title{
    width: 80%;
    font-size: 20px;
    color: #333333;
    margin-top: 20px;
    text-align: center;
    line-height: 30px;
  }
}
.link{
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  .linkItem{
    width: 200px;
    height: 40px;
    margin: 10px;
    border: 1px solid #DDD;
    line-height: 40px;
    font-size: 16px;
    color: #333;
    text-align: center;
    cursor: pointer;
  }
}
</style>

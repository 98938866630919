<template>
  <div class="main">
      <div class="title">{{title}}</div>
      <div class="list-view">
          <div class="list" @click.stop="detailsMethod(items.id)" v-for="(items,index) in list" :key="index">
              <div class="list-img">
                  <img :src="items.img" alt="">
              </div>
              <div class="list-content">
                  <div class="titles">{{'《'+items.name+'》'}}</div>
                  <div class="author">{{items.opus}}</div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        title:{
            type:String,
            default:''
        },
		list:{
			type:Array,
			default:[]
		}
    },
    methods:{
        detailsMethod(index){
            this.$emit('detailsMethod',index);
        }
    }
}
</script>

<style scoped lang="scss">
.main{
    width: 285px;
    background: #FCFCFC;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 0px 0px 4px 4px;
    .title{
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        padding: 15px 0px 0px 15px;
    }
    .list-view{
        width: 255px;
        padding: 25px 0px 0px 15px;
        .list{
            display: flex;
            justify-items: center;
            align-items: center;
            margin-bottom: 20px;
            cursor: pointer;
            .list-img{
                width: 40px;
                height: 40px;
                display: flex;
                justify-items: center;
                align-items: center;
				> img{
					width: 100%;
					height: 100%;
				}
            }
            .list-content{
                margin-left: 10px;
                width: 190px;
                height: 40px;
                .titles{
                    font-size: 12px;
                    line-height: 17px;
                    color: #333333;
                }
                .author{
                    margin-top: 4px;
                    font-size: 12px;
                    line-height: 17px;
                    color: #999;
                }
            }
        }
    }
}
</style>
<template>
    <div>
        <div class="contents">
            <div class="title">
                <div class="line" :style="{backgroundColor:isSkin,color:'#FFF'}"></div>
                <div class="title-title">{{title}}</div>
            </div>
            <div class="content-title" v-html="content"></div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
		title:{
			type:String,
			default:''
		},
        content:{
            type:String,
            default:''
        }
    },
    computed:{
        isSkin(){
            return this.$store.state.isSkin
        }
    },
}
</script>

<style scoped lang="scss">
.contents{
    width: 100%;
    margin-top: 30px;
    .title{
        width: 100%;
        height: 20px;
        display: flex;
        justify-items: left;
        align-items: center;
        .title-title{
            font-size: 18px;
            line-height: 20px;
            color: #333333;
            margin-left: 10px;
        }
        .line{
            width: 7px;
            height: 20px;
        }
    }
    .content-title{
        width: calc(100% - 7px);
        margin-left: 7px;
        padding-bottom: 20px;
        padding-top: 20px;
        font-size: 14px;
        line-height: 200%;
        letter-spacing: 0.1em;
        color: #333333;
    }
}
</style>
<template>
  <div class="main-views">
    <div class="title">作者简介</div>
    <div class="name-view">
        <div class="name-title" v-html="'作&emsp;&emsp;者：'"></div>
        <div class="name-content">{{item.author}}</div>
    </div>
    <div class="content" v-html="item.authorIntroduce"></div>
  </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    }
}
</script>

<style scoped lang="scss">
.main-views{
    width: 285px;
    // height: 200px;
    background: #FCFCFC;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 0px 0px 4px 4px;
    .title{
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        padding: 15px 0px 0px 15px;
    }
    .name-view{
        width: 255px;
        height: 30px;
        display: flex;
        justify-items: center;
        align-items: center;
        margin-top: 20px;
        padding-left: 15px;
        .name-title{
            font-size: 14px;
            line-height: 30px;
            text-align: justify;
            color: #999999;
            width: 72px;
        }
        .name-content{
            font-size: 14px;
            line-height: 30px;
            text-align: justify;
            color: #333;
        }
    }
    .content{
        width: 255px;
        font-size: 14px;
        line-height: 20px;
        text-align: justify;
        letter-spacing: 0.1em;
        color: #666666;
        padding-left: 15px;
        padding-bottom: 20px;
        padding-top: 15px;
    }
}
</style>
<template>
  <div>
      <div class="title" @click.stop="cilckMethod" :style="cur==index?{backgroundColor:isSkin,color:'#FFF'}:''">{{item.dictLabel}}</div>
  </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        index:{
            type:Number,
            default:0
        },
        cur:{
            type:Number,
            default:0
        }
    },
	created() {
		if (this.cur == 0) {
			console.log('1')
			this.$emit('cilckMethod',0,this.item.dictValue);
		}
	},
    computed:{
        isSkin(){
            return this.$store.state.isSkin
        }
    },
    methods:{
        cilckMethod(){
            this.$emit('cilckMethod',this.cur,this.item.dictValue);
        }
    },
}
</script>

<style scoped lang="scss">
.title{
    width: 144px;
    height: 46px;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px; 
    font-size: 16px;
    line-height: 46px;
    text-align: center;
    color: #333;
    cursor: pointer;
    margin-left: 20px;
}
</style>
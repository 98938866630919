<template>
  <div class="mian-view">
      <div class="title">出版信息</div>
      <div class="content">
          <div class="name-view">
              <div class="name-title" v-html="'书&emsp;&emsp;名：'"></div>
              <div class="name-content">{{item.name}}</div>
          </div>
          <div class="name-view">
              <div class="name-title" v-html="'责任编辑：'"></div>
              <div class="name-content">{{item.compile}}</div>
          </div>
          <div class="name-view">
              <div class="name-title" v-html="'出版日期：'"></div>
              <div class="name-content">{{item.publishDate}}</div>
          </div>
          <div class="name-view">
              <div class="name-title" v-html="'书&emsp;&emsp;号：'"></div>
              <div class="name-content">{{item.bookNumber}}</div>
          </div>
          <div class="name-view">
              <div class="name-title" v-html="'定&emsp;&emsp;价：'"></div>
              <div class="name-content">{{item.price}}</div>
          </div>
          <div class="name-view">
              <div class="name-title" v-html="'页&emsp;&emsp;数：'"></div>
              <div class="name-content">{{item.pages}}</div>
          </div>
          <div class="name-view">
              <div class="name-title" v-html="'装帧方式：'"></div>
              <div class="name-content">{{item.printing}}</div>
          </div>
          <div class="name-view">
              <div class="name-title" v-html="'开&emsp;&emsp;本：'"></div>
              <div class="name-content">{{item.bookSize}}</div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    }
}
</script>

<style scoped lang="scss">
.mian-view{
    width: 285px;
    height: 397px;
    background: #FCFCFC;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 0px 0px 4px 4px;
    overflow: hidden;
    .title{
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        padding: 15px 0px 0px 15px;
    }
    .content{
        margin: 25px 15px 10px 15px;
        width: 255px;
        height: 320px;
        .name-view{
            width: 100%;
            height: 30px;
            display: flex;
            justify-items: center;
            align-items: center;
            margin-top: 10px;
            .name-title{
                font-size: 14px;
                line-height: 30px;
                text-align: justify;
                color: #999999;
                width: 72px;
            }
            .name-content{
                font-size: 14px;
                line-height: 30px;
                text-align: justify;
                height: 30px;
                overflow: hidden;
                width: 180px;
                color: #333;
            }
        }
    }
}
</style>